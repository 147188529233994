  import React from 'react';
  import Rroutes from './routes';
  import Navbar from './component/NavBar/NavBar';

  const App: React.FC = () => {
    return (
      <div>
        <Navbar></Navbar>
        <Rroutes/>
      </div>
    );
  };

  export default App;
