import React from 'react';  
import { Nav, Logo, NavLinks, NavLink } from './NavBar.styles';  
import logo from '../../assets/logo.png';
  
const Navbar: React.FC = () => {  
  return (  
    <Nav>  
      <NavLink to="/">  
        <Logo src={logo} alt="Syrus Wedding" />  
      </NavLink>  
      <NavLinks>  
        <NavLink to="/">Home</NavLink>  
        <NavLink to="/gallery">Gallery</NavLink>  
      </NavLinks>  
    </Nav>  
  );  
};  
  
export default Navbar;  
