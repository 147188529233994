import React from 'react';  
import Modal from 'react-modal';  
import ImageGallery from 'react-image-gallery';  
import 'react-image-gallery/styles/css/image-gallery.css';  
import { customStyles } from './ImageModal.styles';  
  
interface MediaItem {  
  original: string;  
  thumbnail: string;  
}  
  
interface ImageModalProps {  
  isOpen: boolean;  
  onRequestClose: () => void;  
  mediaItems: MediaItem[];  
  startIndex: number;  
}  
  
const ImageModal: React.FC<ImageModalProps> = ({ isOpen, onRequestClose, mediaItems, startIndex }) => {  
  const items = mediaItems.map(item => ({  
    original: item.original,  
    thumbnail: item.thumbnail,  
  }));  
  
  return (  
    <Modal  
      isOpen={isOpen}  
      onRequestClose={onRequestClose}  
      style={customStyles}  
      contentLabel="Media Modal"  
      ariaHideApp={false}  
    >  
      <ImageGallery  
        items={items}  
        startIndex={startIndex}  
        showFullscreenButton={true}  
        showPlayButton={false}  
        showThumbnails={true}  
      />  
    </Modal>  
  );  
};  
  
export default ImageModal;  
