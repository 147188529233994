import React from 'react';  
import Upload from '../../component/Upload/Upload';  
  
const UploadPage = () => {  
  return (  
    <div>  
      <Upload />  
    </div>  
  );  
};  
  
export default UploadPage;  
