import React, { useRef, useState } from 'react';  
import AWS from 'aws-sdk';  
import { s3UploadConfig } from '../../s3-upload-config';  
import {  
  UploadContainer,  
  UploadButton,  
  PreviewContainer,  
  PreviewImageWrapper,  
  PreviewImage,  
  DeleteButton,  
  GuestNameInput,  
  ProgressBarContainer,  
  ProgressBar,  
  ThankYouMessage  
} from './Upload.styles';  
  
interface UploadPageProps {}  
  
interface UploadProgress {  
  [key: number]: number;  
}  
  
const Upload: React.FC<UploadPageProps> = () => {  
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);  
  const [previews, setPreviews] = useState<string[]>([]);  
  const [guestName, setGuestName] = useState<string>('');  
  const [uploadProgress, setUploadProgress] = useState<UploadProgress>({});  
  const fileInputRef = useRef<HTMLInputElement>(null);  
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {  
    const files = Array.from(event.target.files || []);  
    setSelectedFiles(files);  
  
    const filePreviews = files.map((file) => URL.createObjectURL(file));  
    setPreviews(filePreviews);  
  };  
  
  const handleGuestNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {  
    setGuestName(event.target.value);  
  };  
  
  const generateRandomString = (length: number) => {  
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';  
    let result = '';  
    const charactersLength = characters.length;  
    for (let i = 0; i < length; i++) {  
      result += characters.charAt(Math.floor(Math.random() * charactersLength));  
    }  
    return result;  
  };  
  
  const handleUpload = async () => {  
    if (selectedFiles.length === 0 || !guestName) {  
      alert('Please select files and enter a guest name.');  
      return;  
    }  
    console.log(s3UploadConfig.region)
    console.log(s3UploadConfig.endpoint)
    const s3 = new AWS.S3({  
      region: s3UploadConfig.region,  
      accessKeyId: s3UploadConfig.accessKeyId,  
      secretAccessKey: s3UploadConfig.secretAccessKey,  
      endpoint: s3UploadConfig.endpoint,  
      s3ForcePathStyle: true, // Necessary for custom endpoints  
    });  
  
    const uploadPromises = selectedFiles.map((file, index) => {  
      const randomString = generateRandomString(8);  
      const newFileName = `${guestName}-${randomString}.${file.name.split('.').pop()}`;  
  
      const params = {  
        Bucket: s3UploadConfig.bucketName,  
        Key: newFileName,  
        Body: file,  
      };  
  
      return s3.upload(params).on('httpUploadProgress', (evt) => {  
        setUploadProgress((prevProgress) => ({  
          ...prevProgress,  
          [index]: Math.round((evt.loaded / evt.total) * 100),  
        }));  
      }).promise();  
    });  
  
    try {  
      await Promise.all(uploadPromises);  
      alert('Files uploaded successfully!');  
      // Optionally, you can refresh the page or update the state to show the new images in the gallery  
      window.location.reload();  
    } catch (error) {  
      console.error('Error uploading files:', error);  
      alert('Error uploading files');  
    }  
  };  
  
  const handleButtonClick = () => {  
    fileInputRef.current?.click();  
  };  
  
  const handleDelete = (index: number) => {  
    const newFiles = [...selectedFiles];  
    const newPreviews = [...previews];  
    newFiles.splice(index, 1);  
    newPreviews.splice(index, 1);  
    setSelectedFiles(newFiles);  
    setPreviews(newPreviews);  
  };  
  
  return (  
    <UploadContainer>  
      <ThankYouMessage>❤️❤️❤️❤️</ThankYouMessage>  
      <ThankYouMessage>  
        Thank you for coming! We are so grateful to have you with us on our special day.  
      </ThankYouMessage>  
      <ThankYouMessage>  
        感謝您的蒞臨！我們非常感激您能在這個特別的日子與我們同在。  
      </ThankYouMessage>  
      <ThankYouMessage>  
        Please feel free to upload your photos below and share your wonderful moments with us.  
      </ThankYouMessage>  
      <ThankYouMessage>  
        請隨時上傳您的照片並與我們分享您的美好時刻。  
      </ThankYouMessage>  
      <ThankYouMessage>❤️❤️❤️❤️</ThankYouMessage>  
      <GuestNameInput  
        type="text"  
        placeholder="Please enter your name"  
        value={guestName}  
        onChange={handleGuestNameChange}  
      />  
      <input  
        type="file"  
        ref={fileInputRef}  
        style={{ display: 'none' }}  
        accept=".heic, .jpeg, .jpg, .png, .mp4, .mov" // Accept only HEIC, JPEG, PNG, MP4, and MOV formats  
        multiple // Allow multiple file selection  
        onChange={handleFileChange}  
      />  
      <UploadButton onClick={handleButtonClick}>Choose Files</UploadButton>  
      <PreviewContainer>  
        {previews.map((preview, index) => (  
          <PreviewImageWrapper key={index}>  
            <PreviewImage src={preview} alt={`Preview ${index}`} />  
            <DeleteButton onClick={() => handleDelete(index)}>×</DeleteButton>  
            <ProgressBarContainer>  
              <ProgressBar progress={uploadProgress[index] || 0} />  
            </ProgressBarContainer>  
          </PreviewImageWrapper>  
        ))}  
      </PreviewContainer>  
      {selectedFiles.length > 0 && <UploadButton onClick={handleUpload}>Upload Media</UploadButton>}  
    </UploadContainer>  
  );  
};  
  
export default Upload;  
