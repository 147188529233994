import React from 'react';  
import Modal from 'react-modal';  
import { customStyles } from './VideoModal.styles';  
  
interface VideoModalProps {  
  isOpen: boolean;  
  onRequestClose: () => void;  
  videoUrl: string;  
}  
  
const VideoModal: React.FC<VideoModalProps> = ({ isOpen, onRequestClose, videoUrl }) => (  
  <Modal  
    isOpen={isOpen}  
    onRequestClose={onRequestClose}  
    style={customStyles}  
    contentLabel="Video Modal"  
    ariaHideApp={false}  
  >  
    <video controls style={{ width: '100%', height: 'auto' }}>  
      <source src={videoUrl} type="video/mp4" />  
      Your browser does not support the video tag.  
    </video>  
  </Modal>  
);  
  
export default VideoModal;  
