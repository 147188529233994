interface S3Config {  
    bucketName: string;  
    region: string;  
    accessKeyId: string;  
    secretAccessKey: string;  
    endpoint: string;  
  }  
    
export const s3ReadConfig: S3Config = {  
  bucketName: process.env.REACT_APP_S3_BUCKETNAME || '',  
  region: process.env.REACT_APP_S3_REGION || '',  
  accessKeyId: process.env.REACT_APP_S3_READ_ACCESS_KEY_ID || '',  
  secretAccessKey: process.env.REACT_APP_S3_READ_SECRET_ACCESS_KEY || '',  
  endpoint: process.env.REACT_APP_S3_ENDPOINT || ''  
};  
