import React from 'react';
import { Routes, Route } from 'react-router';
import { UploadPage, GalleryPage } from './page/page-routes';

export const Rroutes = () => {
  return (
    <Routes>
      <Route path='*' element={<UploadPage />} />
      <Route path='/' element={<UploadPage />} />
      <Route path='/gallery' element={<GalleryPage />} />
    </Routes>
  );
};

export default Rroutes;
