import styled from 'styled-components';  
import { Link } from 'react-router-dom';  
  
export const Nav = styled.nav`  
  background: #ffd5d5;  
  padding: 0.1rem;  
  display: flex;  
  justify-content: space-between;  
  align-items: center;  
`;  
  
export const Logo = styled.img`  
  height: 120px;  
`;  
  
export const NavLinks = styled.div`  
  display: flex;  
  gap: 1rem; /* Adds space between the links */  
  margin-right: 1rem; /* Adds space between the links and the right edge */  
`;  
  
export const NavLink = styled(Link)`  
  color: white;  
  text-decoration: none;  
  margin: 0 1rem;  
  font-size: 1.5rem;  
  font-family: "Baskerville";  
`;  
