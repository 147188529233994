import React from 'react';  
import Gallery from '../../component/Gallery/Gallery';  
  
const GalleryPage = () => {  
  return (  
    <div>  
      <Gallery />  
    </div>  
  );  
};  
  
export default GalleryPage;  
